.accordion {
  @apply my-12;

  &-item {
    @apply bg-white border-0 rounded-none;
  }

  &-header {
    @apply border-t border-gray-200 mb-0;
  }

  &-button {
    @apply relative flex items-center w-full pt-7.75 pb-8.25 pr-2 font-semibold text-gray-800 text-left bg-white border-0 shadow-none rounded-none transition focus:outline-none;

    &::after {
      width: 1.75rem;
      height: 1.75rem;
      background-size: 1.75rem;

      @apply -mt-1.25;
    }

    &::after,
    &:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2319A5AF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    &:not(.collapsed) {
      @apply text-gray-800 shadow-none;
    }
  }

  &-body {
    @apply pb-10 pl-6.25 pr-1;

    &,
    & p {
      @apply font-normal text-2sm leading-2relaxed;
    }
  }

  &-collapse {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    max-height: 0;

    &:not(.show) {
      display: block;
    }
  }
}
