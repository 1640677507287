.cookie-notification {
    padding: 22px 20px;
    @apply bg-geb-green-secondary fixed bottom-0 z-30 w-full text-white flex justify-center;

    .container {
        @apply flex justify-between
    }

    &__content {
        @apply text-current font-semibold pr-5 text-lg;

        a {
            @apply text-current underline;
        }
    }
    
    @media print {
        display: none !important;
    }
}