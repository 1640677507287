html {
  scroll-behavior: smooth;
}

i>svg,
span>svg {
  width: 100%;
  height: 100%;
}

.fill-none {
  fill: none;
}

.xl\:container:not(.megamenu) {
  @media screen and (min-width: 1325px) {
    max-width: 1325px !important;
  }
}

.iframe--document-embed {
  max-width: 100%;
  max-height: 80vh;

  @media screen and (max-width: 768px) {
    height: 450px !important;
  }
}

.c-block-preview {
  min-height: auto !important;
  overflow: inherit !important;

  .aos-init {
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.force-full-width {
  margin-left: -400px !important;
  margin-right: -400px !important;

  &>.full-container {
    @apply px-4;
    @apply w-screen;
    @apply mx-auto;
    @apply xl:container;

    &:not(.custom-list-files) {
      z-index: 10;
    }
  }

  &.z-auto {
    &>.full-container {
      z-index: auto;
    }

    &+.force-full-width {
      z-index: -1;
    }
  }

  &>.full-container-image {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }

    picture {
      display: flex;
    }
  }

  @media screen and (min-width: 1920px) {
    margin-left: calc(-50vw + 600px) !important;
    margin-right: calc(-50vw + 600px) !important;
  }
}

.full-width-image {
  video {
    max-width: none;
    width: 100vw;
    transform: translateX(-50%);
    margin-left: 50%;
  }

  & picture,
  & figure,
  & .picture {
    @media screen and (min-width: 768px) {
      overflow: hidden;
      margin-left: calc(-80vw + 600px);
      margin-right: calc(-80vw + 600px);

      img,
      .img {
        min-width: 100vw;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}

.section {
  &-block {
    @apply py-10
  }

  &-title {
    @apply text-3xl-2alt md:text-4xl-3alt font-bold leading-tight;
  }

  &-row {

    &.instagram-title,
    &.twitter-title {
      @apply flex flex-wrap gap-x-6 gap-y-2 justify-between;

      .section-title,
      .section-subtitle {
        @apply text-xl md:text-2xl-alt;

        a {
          @apply text-geb-dark-600;
        }
      }
    }
  }

  &-description {
    @apply text-base md:text-lg;
  }

  &-description {
    @apply text-base md:text-lg;
  }

  &-container {
    @apply flex flex-col gap-8 relative;

  }
}

.instagram-wrapper {
  margin-left: 50%;
  margin-bottom: -2.5em;
  @apply mt-6 w-screen transform -translate-x-1/2;
}

.btn-tgi {
  @apply flex flex-grow justify-center md:flex-grow-0 px-4 pt-3.5 pb-3 mb-px md:mb-0 md:px-5.5 md:pt-4.5 md:pb-4 rounded-sm md:rounded-2sm border md:border-2 border-geb-link text-geb-link tgi-blue-light-button-outline text-2xxs leading-none font-semibold md:text-4xxs md:leading-tighter md:font-semibold hover:bg-white hover:bg-opacity-5 hover:shadow-md uppercase tracking-widest md:tracking-2widest text-center;
}

.block-card-list {

  &.bg-geb-dark-700 {
    a span {
      color: white !important;
    }
  }
}