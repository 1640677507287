.nav {
  &-tabs {
    @apply flex flex-col md:flex-row flex-wrap list-none mb-4 bg-geb-blue-200;
  }

  &-item {
    @apply hover:bg-geb-blue-50 hover:bg-opacity-50;
  }

  &-tabs &-link {
    @apply block font-semibold text-geb-green-secondary text-xl leading-tight uppercase px-6.75 py-4;

    &.active {
      @apply bg-geb-blue-75 text-geb-dark-900;
    }
  }
}