.richtext-content,
.block-richtext {
  @import "fonts";
  @import "list";
  @import "table";
  @import "media";
}

.block-richtext {
  @apply my-12;
}