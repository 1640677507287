@mixin animation-zoom-in {
  @apply transition transform ease-in-out delay-150 duration-300;
}

.animate-button {
  @include animation-zoom-in();
  @apply hover:-translate-y-1 hover:scale-110;
}

.animate-image {
  @apply overflow-hidden;

  img {
    @include animation-zoom-in();
    @apply hover:scale-125 sm:hover:scale-110 md:hover:scale-125;
  }
}

/* .hover\:underline { text-decoration: none !important;}
a.hover\:underline, .hover\:underline a  {
  text-decoration: none !important;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .5s;

  &:hover {
    background-size: 100% 2px;
  }
} */

/* .animate-list > li:not(.hover\:underline) > a,
.animate-list > a:not(.hover\:underline),
.animate-list:not(.hover\:underline) > a {
  @apply transform transition hover:translate-x-1.5;
} */


[class*="hover\:text-"]:not(.hover\:underline, .animate-button, .transition-none) {
  transition-property: color;
  transition-duration: 600ms;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease-out;  
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}