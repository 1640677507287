.button {
  @apply transition-colors  inline-flex text-white font-medium rounded px-3 py-2 bg-blue-500;

  &--cookie {
    padding: 13px 22px;
    @apply bg-geb-green-900 text-lg font-bold;
  }
  &-outline{
    @apply flex py-3 px-5 justify-center rounded-sm md:rounded-2sm border-2 border-current text-xs md:text-sm md:font-roboto font-bold hover:bg-white hover:bg-opacity-5 hover:shadow-md uppercase tracking-2widest text-center;
  }
}

.text-with-button {
  a {
    @apply text-white #{!important};
  }
}

.btn {
  &-default {
    @apply inline-block bg-geb-green-secondary hover:bg-geb-green-800 px-5 md:px-15 py-5 rounded-3sm shadow-2md uppercase leading-3 text-center text-white;
    span {
      @apply text-2xs font-extrabold md:font-semibold tracking-2widest px-0.5 py-px
    }
  }
  &-outline {
    @apply inline-block border border-geb-turquoise-bright text-geb-turquoise-bright px-5 md:px-15 py-5 rounded-3sm shadow-2md uppercase leading-3 text-center;
    span {
      @apply text-2xs font-extrabold md:font-semibold tracking-2widest px-0.5 py-px
    }
  }
}

.accordion-button:focus{
  box-shadow: 0 0 0 2px black, inset 0 0 0 2px white!important;
}