h1, h2, h3, h4, h5, h6 {
  @apply font-semibold clearfix;
}

h1 {
  @apply text-6xl leading-tighter;
}
h2 {
  @apply text-4xl-2alt leading-tighter;
}
h3 {
  @apply text-1.5xl leading-normal;
}
h4 {
  @apply text-lg leading-2tighter;
}
h5 {
  @apply text-base;
}
h6 {
  @apply text-xs;
}

p {
  @apply text-lg leading-5tighter font-medium;
}

a {
  @apply text-geb-green-secondary hover:underline;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}