.vue-video {
	@apply rounded-md md:rounded-xl relative overflow-hidden w-full;
	@media(max-width: 639px){
		max-width: none!important;
	}
	video {
		@apply block w-full;
	}
	.video{
		&-btn{
			transform: translate(-50%, -50%);
			@apply absolute w-1/5 top-1/2 left-1/2 text-white cursor-pointer;
		}
		&-poster {
			@apply absolute inset-0;
			img {
				@apply w-full h-full block object-cover;
			}
		}
	}
}