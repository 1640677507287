.title {
  @apply text-3xl;

  &.is-1 {
    @apply text-5xl;
  }

  &.is-2 {
    @apply text-4xl;
  }

  &.is-3 {
    @apply text-3xl;
  }

  &.is-4 {
    @apply text-2xl;
  }

  &.is-5 {
    @apply text-xl;
  }

  &.is-6 {
    @apply text-lg;
  }
}