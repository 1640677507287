form:not(.form-search, .form-newsletter, .form-search-page, .form-investor) {
  @apply bg-white px-6 py-8 mb-4 shadow-md rounded-md;

  label {
    @apply block text-gray-700 text-sm font-bold mb-4;

    & > select,
    & > input {
      @apply mt-2;
    }
  }

  input,
  select,
  textarea {
    @apply block shadow appearance-none w-full border border-gray-200 bg-white focus:bg-white text-gray-700 leading-tight focus:outline-none focus:border-gray-500 rounded;
  }

  input {
    @apply py-2 px-3;
  }

  select {
    @apply py-3 px-4 pr-8;

    background-position-x: calc(100% - 0.25rem);
    background-position-y: 0.4375rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg class='h-10 w-10 text-gray-200 fill-current' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 30'%3E%3Cpath d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'/%3E%3C/svg%3E");
  }

  button {
    @apply bg-geb-blue-primary hover:bg-geb-dark-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none;
  }

  .formRadioListLabel {
    &,
    .formRadioLabel {
      @apply flex gap-2;
    }

    .formRadioLabel {
      @apply items-center;

      input {
        @apply w-auto m-0;
        appearance: auto;
      }
    }
  }
}

.custom-select {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  &::after {
    content: "";
    position: absolute;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAA/CAYAAAD+K9hLAAAAAXNSR0IArs4c6QAAAjxJREFUWAntV71uE0EQ/saXBp2Ly0mgk2gWI2LKo4PO7gDZyfEEQEmFeYI4T0B4gognwIAbKkOXLi7hmmwXRynsSLZIwXnQLreWdbk7O1JAQtptZnfn55v9tMV8gF2WAcuAZeD/YeCWaEfB3e3nV+3YuUrCTRGFDvEAjMj16+ezcXy4bj6tGxiISICSAQCxyGFujmT/6+JcsqmU+BYuISLPgBAwASC1k+hAN7CILN6sBfSzkhyYlzBTJ2HnWQoomH590I0UY2jPSqCg1tolRqSjmfZG8tP7M9kbMuONuiNQeFFJ3q7AQelnCET7BUD7qggTeqfHn1+ZgtNJPKx6WwSiBoCw6t/HdPzjm/FnbemLiPh1miBvzJ2X2eSR7HdVA+qemTtZ//J5Y/mQ3TPjIwge2GlK2VOf4NJSDVxQEpL5IJci7IVlwDJgGbAMWAYsA5YBy8A/YKB8Ur3THrh+PZyN4y9Fvaghv7p578j161QmYwon1VQlNIjRCWrbu3lAy1KGGDt5MeauEGgke9KMu2Du5qk8pSSMykjY0UO/KZy1hUAqMJ23tRbiOe/fFk9DU0CrDNCfM9OeUhjGl2dXKj5FD1FyxICnBRg7TarMd5hZqwxifnci+6UDvgJeCaSCAtFqgEjJSjB4qDSR2Z8e9x+o/apV+utM8nQSS9ernxPhMYGC9F4SbzyZTr7nqgyTa+xaQCp4NokPXX9rk0APtaxk55H6MKbQtdug1upqKq+9si1oGbAMWAb+GgO/Ael5tXTES2uIAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 10px;
    height: 28px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }
}

.investor-services__iframe {
  height: 300px;

  @media screen and (min-width: 728px) and (max-width: 767px), (min-width: 781px) and (max-width: 908px) {
    height: 185px;
  }

  @media screen and (min-width: 909px) and (max-width: 959px) {
    height: 325px;
  }
}

.investors-account {
  @apply relative flex;

  p {
    @apply text-geb-green-secondary cursor-pointer text-2sm font-medium;
  }

  &__content {
    display: flex;
  }

  &__content:hover &-tooltip {
    display: block;
    opacity: 1;
  }

  &-tooltip {
    @apply hidden opacity-0 transition-opacity absolute bottom-full left-3 mb-1 z-10 p-2 text-white text-center text-xs rounded-md;
    background-color: rgb(82, 82, 91);
    width: calc(100% - 20px);

    @media screen and (min-width: 440px) {
      @apply w-80;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 8px;
      left: 0;
      top: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 30px;
      top: 100%;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgb(82, 82, 91);
    }
  }
}
.newsletter-radio{
  @apply text-sm;
  .check-input {
    width: 1em;
    aspect-ratio: 1;
    @apply inline-block bg-white mr-1 rounded-full align-middle pointer-events-none;
  }
  [type="radio"] {
    vertical-align: middle;
    opacity: 0;
    cursor: pointer;
    width: 1.1em;
    margin-right: -1.3em;
    aspect-ratio: 1;
    display: inline-block;
  }
  [type="radio"]:checked+.check-input {
    border: 0.25em solid white;
    @apply bg-geb-green-secondary;
  }
  label{
    @apply cursor-pointer;
  }
}