.breadcrumbs{
	@apply flow-root relative z-10;
	&.block-section{
		@apply py-0;
	}
	&-list {
		@apply flex flex-wrap items-center -m-2;
	}
	&-item {
		@apply m-2
	}
	&-wrapper {
		@apply flex items-center;
		
		> span {
			@apply flex-shrink-0;
		}
	}
	&-icon {
		@apply w-3 md:w-4;

		.home & {
			@apply w-4 md:w-5;
		}
	}
	&-text {
		@apply ml-3 font-semibold text-xs md:text-base;

		a:hover {
			@apply underline;
		}
	}
}