.icon_language_es,
.icon_language_en {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: cover;
}

.icon_language_en {
  background-image: url("../../../public/images/icon_language_en.png");
}

.icon_language_es {
  background-image: url("../../../public/images/icon_language_es.png");
}

#input-search {
  @apply transition-all duration-500 w-0;
  &.open,
  &:focus {
    @apply w-44;
  }
  & + button[type="submit"] {
    @apply pointer-events-none;
  }
  &.open + button[type="submit"] {
    @apply pointer-events-auto;
  }
}
.header {
  &-logo {
    width: 213px;
    height: 45px;
    background-position: -3px -2px;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 1024px) {
      width: 151px;
      height: 82px;
      background-position: -2px -54px;
      align-self: flex-start;
      margin-top: 0 !important;
      @apply mb-2.5;
    }
  }

  &-nav {
    &.has-shadow {
      &::before {
        content: "";
        position: absolute;
        top: 100%;
        width: 100%;
        height: 100px;
        margin-top: -40px;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0.480729) 42.71%,
          rgba(0, 0, 0, 0) 100%
        );
        mix-blend-mode: luminosity;
        opacity: 0.1;
        z-index: -1;
        pointer-events: none;
      }
    }

    &__primary-list {
      ul {
        gap: 0;
        // @apply space-x-1.5;
      }
    }

    &__item {
      // transition: all .5s;

      &--link {
        position: relative;
        top: 1px;

        & > a {
          border-width: 0;
          @apply pb-1.25 md:pb-5.25;

          &::before {
            content: "";
            position: absolute;
            display: block;
            left: 50%;
            bottom: 0;
            width: 0;
            height: 5px;
            background-color: currentColor;
            transition: all .5s;
          }
        }

        &:hover,
        &:focus {
          & > a {
            &::before {
              left: 0;
              width: 100%;
            }
          }
        }

        &-secondary {
          @apply text-trueGray-500;
        }
      }
    }
  }
}

.tgiweb,
.tgiweb_en {
  .header {
    &-logo {
      @media screen and (min-width: 768px) {
        width: 185px;
        height: 70px;
        background-position: -3px -53px;
      }
    }
  }
}

#header:not(.is-searching) {
  .header-nav__item--link {
    &-primary {
      &.group::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        top: 100%;
        margin-top: 2.45rem;
        transform: translate(-50%, -50%);
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 15px solid #001a31;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.7s ease-out;
      }

      &.group:not(.is-scrolling):hover,
      &.group:not(.is-scrolling):focus {
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4rem;
          left: 0;
          top: 90%;
          z-index: 50;
        }

        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
      &.open {
        > a {
          &::before{
            left: 0;
            width: 100%;
          }
        }
        &::after {
          opacity: 1;
          visibility: visible;
        }
        .submenu,
        .megamenu {
          opacity: 1;
          visibility: visible;
          overflow-y: auto;
        }
      }
    }
  }
}
