.axis-sidebar {
  &--container {
    right: -110%;
    transition: all 0.8s ease-in-out;
  }

  &--backdrop {
    @apply fixed inset-0 bg-black bg-opacity-0;
    transition: all .8s ease;
  }

  &--close-button {
    opacity: 0;
  }

  &.open &--close-button {
    opacity: 1;
  }

  &.open &--container {
    right: 0;
  }

  &.open &--backdrop {
    @apply bg-opacity-50;
  }
}