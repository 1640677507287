/*

  Fonts
  ----------------------------------------------------------------------------

  Use this file to register all the fonts for the project using @font-face or Google fonts.
  After registering your fonts, it is recommended that you create variables so it's easier to
  use the fonts in your rules.

  For example, if you load Open Sans, at the end of this file create a variable like:
  $font-open-sans: 'Open Sans';

*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
$font-inter: 'Inter', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
$font-montserrat: 'Montserrat', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');

body {
  font-family: $font-montserrat !important;
}