@mixin bg_gradient($gradient-color) {
  &::before {
    background-color: $gradient-color;
  }

  &::after {
    background-image: linear-gradient(
      transparent,
      transparent 50%,
      rgba($gradient-color, 0.3) 55%,
      rgba($gradient-color, 0.8) 65%,
      rgba($gradient-color, 0.9) 70%,
      rgba($gradient-color, 0.95) 80%,
      $gradient-color 95%,
      $gradient-color 100%
    );
  }
}

.tgiweb .landing-page__background-image,
.tgiweb_en .landing-page__background-image {
  &.gradient-primary-a .picture,
  &.gradient-primary-a picture {
    @include bg_gradient(#3c75b7);
  }

  &.gradient-secondary-a .picture,
  &.gradient-secondary-a picture {
    @include bg_gradient(#56aadd);
  }

  &.gradient-primary-b .picture,
  &.gradient-primary-b picture {
    @include bg_gradient(#4da3ad);
  }

  &.gradient-secondary-b .picture,
  &.gradient-secondary-b picture {
    @include bg_gradient(#19a5af);
  }
}

.landing-page__background-image {
  z-index: -10;

  &.gradient-primary-a .picture,
  &.gradient-primary-a picture {
    @include bg_gradient(#2376bc);
  }

  &.gradient-secondary-a .picture,
  &.gradient-secondary-a picture {
    @include bg_gradient(#001a31);
  }

  &.gradient-tertiary-a .picture,
  &.gradient-tertiary-a picture {
    @include bg_gradient(#f5fbfe);
  }

  &.gradient-quaternary-a .picture,
  &.gradient-quaternary-a picture {
    @include bg_gradient(#eefcfe);
  }

  &.gradient-primary-b .picture,
  &.gradient-primary-b picture {
    @include bg_gradient(#00a34b);
  }

  &.gradient-secondary-b .picture,
  &.gradient-secondary-b picture {
    @include bg_gradient(#19a5af);
  }

  &.gradient-white .picture,
  &.gradient-white picture {
    @include bg_gradient(#ffffff);
  }

  &.gradient-geb-blue-primary .picture,
  &.gradient-geb-blue-primary picture {
    @include bg_gradient(#2376bc);
  }

  &.gradient-geb-blue-secondary .picture,
  &.gradient-geb-blue-secondary picture {
    @include bg_gradient(#001a31);
  }

  &.gradient-geb-blue-50 .picture,
  &.gradient-geb-blue-50 picture {
    @include bg_gradient(#f5fbfe);
  }

  &.gradient-geb-blue-100 .picture,
  &.gradient-geb-blue-100 picture {
    @include bg_gradient(#eefcfe);
  }

  &.gradient-geb-green-primary .picture,
  &.gradient-geb-green-primary picture {
    @include bg_gradient(#00a34b);
  }

  &.gradient-geb-green-secondary .picture,
  &.gradient-geb-green-secondary picture {
    @include bg_gradient(#19a5af);
  }
  .video-wrapper {
    position: relative;
    &::before {
      position: absolute;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      bottom: 0px;
      z-index: 10;
      height: 180px;
      --tw-content: "";
      content: var(--tw-content);
      background: linear-gradient(
        to top,
        #fff 0%,
        #ffffffe6 25%,
        #fff3 75%,
        transparent 100%
      );
    }
    @media (min-width: 768px) {
      &:before {
        padding-bottom: 430px;
      }
    }
  }
  video {
    object-fit: cover;
  }

  .picture,
  picture {
    position: relative;
    overflow: visible;
    --heightBefore: 0px;
    --topAfter: 5px;

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &.has-before::before {
      content: "";
      height: calc(var(--heightBefore) + 10px);
      bottom: -5px;
    }

    &::after {
      content: "";
      height: 100%;
      top: var(--topAfter);
    }

    img,
    .img {
      height: 626px;

      @media screen and (max-width: 1024px) {
        object-position: top 0% right 29%;
      }

      @media screen and (min-width: 768px) {
        width: auto;
        height: 1157px;
      }
    }
  }
}
