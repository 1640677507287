@layer utilities {
  @variants responsive {
    .row-start-span-2 {
        grid-row-start: span 2;
    }
  }

  .hyphens-auto {
    hyphens: auto;
    word-wrap: break-word;
    // overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  .word-break {
    word-break: break-word;
  }
}
