.content {

  *:not(:last-child) {
    @apply mb-2;
  }

  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  h5 {
    @apply text-xl;
  }

  h6 {
    @apply text-lg;
  }

  ul {
    @apply list-disc;
    @apply pl-4;
  }
}