.grill{
  &-wrapper{
    @apply flex flex-wrap -m-1 md:-m-2 lg:-m-4 justify-center;
  }
  &-title {
    position: absolute;
    inset:0;
    top: 30%;
    z-index: 1;
    @apply text-sm md:text-xl leading-none font-semibold text-white p-4 md:px-10 md:py-8 content-end;
    &::before {
      content: '';
      position: absolute;
      inset:0;
      opacity: 0.64;
      z-index: -1;
      background: linear-gradient(to top, #000, transparent );
    }
  }
  &-card {
    @apply p-1 md:p-2 lg:p-4 w-1/2 md:w-1/3 lg:w-1/5 flex-grow md:flex-grow-0;
    &-wrapper{
      @apply flex flex-col relative overflow-hidden rounded-lg md:rounded-1.5xl;
    }
    figure {
      aspect-ratio: 230/320;
      @apply relative w-full;
      img {
        @apply absolute w-full h-full object-cover;
      }
    }
    &:last-child {
      @media(max-width: 767px){
        figure{
          aspect-ratio: 320/230;
        }
      }
    }
  }
}