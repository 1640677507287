table {
  @apply max-w-full pb-0 scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 scrollbar-thumb-rounded scrollbar-track-rounded;
  width: 100% !important;
  overflow-x: auto;
  
  thead {
    @apply bg-geb-dark-800 text-white;

    tr {
      th {
        @apply font-semibold text-2xs 2md:text-sm lg:text-2base xl:text-2lg;
      }
    }
  }

  tbody {
    @apply text-geb-dark-900;

    tr {
      @apply bg-geb-blue-250;

      td {
        @apply font-medium text-4xxs 2md:text-xs lg:text-sm xl:text-base;
      }
    }
    
    tr:nth-child(even) {
      @apply bg-geb-blue-75;
    }
  }

  tr { 
    th,
    td {
      @apply table-auto leading-normal px-4 py-2 lg:px-6 lg:py-3.5 border-2 border-white;
    }
  }
}