.section {
  &-banner {
    aspect-ratio: 2/1;
    margin-left: 50%;
    padding-top: 25%;
    padding-bottom: 10%;
    @apply transform -translate-x-1/2 relative w-screen bg-cover bg-center;
    figure {
      @apply absolute inset-0;
      img {
        @apply w-full h-full object-cover;
     }
    }

    .full-container {
      @apply px-4 w-screen mx-auto xl:container;
    }
    .pretitle {
      @apply text-2.5base md:text-2xl-alt font-medium text-geb-green-400;
    }
    .title {
      @apply text-4xl-alt md:text-6xl-3alt font-semibold leading-tight text-white;
      b,
      strong {
        @apply text-geb-green-400 font-semibold;
      }
    }
    &-content {
      @media (min-width: 1024px) {
        width: 45%;
        min-width: 400px;
      }
      @apply flex flex-col gap-4 md:gap-10;
    }
  }
}
