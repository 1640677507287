.clickable-link {
    cursor: pointer;
}

.search-page {
    padding-bottom: 100px;

    &__results {
        padding-left: 16px;

        .results-container {
            .search-results__item {
                display: flex;
                flex-direction: column;

                .content-type {
                    display: block;
                    font-weight: 500;
                    font-size: 15px;
                    // font-variant: small-caps;
                    color: #001324;
                }

                .content-link {
                    display: block;
                    font-family: $font-montserrat;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 1.3;
                    margin-top: 8px;
                    transition-property: color;
                    transition-duration: 600ms;
                    @apply text-geb-green-secondary hover:text-geb-green-900;
                }

                .content-resume {
                    display: block;
                    font-family: $font-montserrat;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 1.6;
                    margin-top: 12px;
                    color: #001324;
                }
            }
        }

        .pagination-container {
            display: flex;
            width: fit-content;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.8rem;
            font-size: 0.8125rem;
            margin-top: 3.25rem;

            .page-link {
                display: flex;
                align-items: center;
                gap: 1rem;

                .link-icon {
                    width: 16px;
                    display: inline-block;
                }

                &.prev {
                    .link-icon {
                        transform: rotate(-180deg);
                    }
                }
            }

            .page-number {
                margin: 0 16px;
            }
        }
    }

    &__sidebar {
        .search-sidebar {
            &__option {
                cursor: pointer;

                > label {
                    display: block;
                    padding: 17px 14px 16px;
                    border: 1px solid #e5e7eb;
                    font-family: $font-montserrat;
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 1.4;

                    input {
                        margin-right: 10px;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }
    }
}
