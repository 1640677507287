$promo-gradient-color: rgb(255, 255, 255);

// Gradient for backgrounds and figure of promo content
%image-gradient-effect {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  max-height: 28.375rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.480729) 42.71%, rgba(0, 0, 0, 0) 100%);
  // mix-blend-mode: luminosity;
  opacity: 0.3;
  transform: matrix(1, 0, 0, -1, 0, 0);
  pointer-events: none;
}

.has-gradient:not(figure) {
  position: relative;
  overflow: hidden;

  &::before {
    @extend %image-gradient-effect;
  }
}

.image-is-center .has-gradient:not(figure) {
  overflow: visible;
}

figure,.picture {
  &.has-gradient {
    position: relative;
    overflow: hidden;
  
    &::after {
      @extend %image-gradient-effect;
    }
  }
}

// Gradient for promo content background images
%promo-image-gradient-effect {
  figure,
  picture, .picture {
    position: relative;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: calc(100% + 5px);
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      background-image: linear-gradient(
        transparent,
        transparent 50%,
        rgba($promo-gradient-color, 0.3) 55%,
        rgba($promo-gradient-color, 0.8) 65%,
        rgba($promo-gradient-color, 0.9) 70%,
        rgba($promo-gradient-color, 0.95) 80%,
        $promo-gradient-color 95%,
        $promo-gradient-color 100%
      );
    }
  }
}

.image-is-top,
.image-is-bottom,
.image-is-center {
  .full-container-image {
    @extend %promo-image-gradient-effect;
  }
}

.image-is-bottom .full-container-image {
  figure::after,
  picture::after {
    transform: scaleY(-1);
    margin-top: -5px;
  }
}

.image-is-center .full-container-image {
  figure::after,
  picture::after {
    background-image: linear-gradient(
      $promo-gradient-color 0%,
      $promo-gradient-color 5%,
      rgba($promo-gradient-color, 0.8) 15%,
      rgba($promo-gradient-color, 0.4) 30%,
      transparent 45%,
      transparent 55%,
      rgba($promo-gradient-color, 0.4) 70%,
      rgba($promo-gradient-color, 0.8) 85%,
      $promo-gradient-color 95%,
      $promo-gradient-color 100%
    );
  }
}


.twitter-wrapper {
  gap: 8px 30px;
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
}

.twitter-card {
  @apply rounded-lg border border-gray-300 bg-white flex;
  &-wrapper {
    @apply p-4 flex flex-col flex-grow;
  }
  &-topbar {
    @apply grid gap-3 relative;
    grid-template-columns: auto 1fr;
  }
  &-icon {
    @apply absolute right-0 w-5 text-geb-link;
  }
  
  &-image {
    width: 45px;
    aspect-ratio: 1;
    @apply overflow-hidden rounded-full relative;
    img {
      @apply absolute w-full h-full object-cover;
    }
  }
  
  
  &-user {
    @apply font-bold leading-normal;
    &-tag {
      font-size: 0.85em;
      @apply opacity-50;
    }
  }
  
  &-rate {
    @apply flex my-4 gap-2;
  }
  
  &-rate i {
    width: 1.1em;
  }
  
  &-content {
    font-size: 0.9em;
    a, b,strong {
      @apply text-geb-link font-bold;
    }
  }
  &-date{
    font-size: 0.7em;
    @apply opacity-75 text-right mt-auto;
  }
}


