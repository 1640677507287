.flicking-viewport {
  height: auto!important;
}
.slide {
  &-images{
    .slide-main {
      img {
        aspect-ratio:16/9;
        @apply block w-full object-center object-cover;
      }
    }
    .flicking {
      &-arrows{
        @apply text-geb-green-secondary fill-current;
      }
      &-pagination-bullet{
        @apply bg-geb-green-secondary;
        &-active,
        &:hover{
          @apply bg-white;
        }
      }
    }
  }
}

.slide{
  &-main{
    img {
      @apply rounded-2xl;
    }
  }
  &-caption{
    figcaption {
      @apply py-5;
    }
  }
  &-thumbs{
    &-items{
      width:100px;
      aspect-ratio:10/6;
      @apply  relative;
    }
    img {
      @apply rounded-md absolute w-full h-full object-cover object-center;
    }
    .flicking {
      &-panel{
        @apply py-2 px-1 opacity-50 cursor-pointer grayscale transition;
        &.active,
        &:hover{
          @apply opacity-100 grayscale-0;
        }
      }
    }
  }
}
.flicking {
  &-viewport{
    transition: height 600ms;
  }
  &-arrows{
    aspect-ratio: 1;
    @apply text-geb-green-secondary flex z-10 absolute w-8 top-2/4 bg-white rounded-full -translate-y-1/2 cursor-pointer transition-opacity duration-500 ease-in-out;
    .slide-images &{
      width: 35px;
      @apply text-white bg-transparent rounded-none;
    }
    i {
      height: 45%;
      @apply m-auto block;
      .slide-images &{
        @apply h-full
      }
    }
  }
  &-arrow{
    &-prev{
      @apply left-0;
      .slide-images &{
        @apply left-4;
      }
    }
    &-next{
      @apply right-0;
      .slide-images &{
        @apply right-4;
      }
    }
    &-disabled{
      @apply pointer-events-none opacity-0;
    }
  }
  &-pagination-bullets{
    @apply flex flex-wrap py-2 px-5 justify-center w-full;
    .slide-images &{
      @apply absolute bottom-0 z-10;
    }
  }
  &-pagination-bullet{
    width: 11px;
    height: 11px;
    @apply bg-geb-green-secondary m-2 cursor-pointer block rounded-full;
    &-active,
    &:hover{
      @apply bg-geb-dark-750;
    }
  }
}