.news{
  &-container {
    @apply grid gap-8 grid-cols-1 lg:grid-cols-4;
  }
  &-content{
    @apply flex flex-col gap-8;
  }
  &-wrapper{
    @apply grid grid-cols-1 md:grid-cols-3 gap-8 col-span-3;
  }
  &-title {
    @apply font-medium text-base md:text-2lg leading-tight;
  }
  &-card {
    box-shadow: 0 0 20px 0 rgb( 0 0 0 / 0.08 );
    border-radius: 13px;
    &-wrapper{
      @apply py-8 px-7 flex flex-col gap-5;
    }
  }
  &-date{
    @apply flex gap-4 uppercase items-center font-medium text-xs;
    .date-icon {
      @apply w-4 text-tgi-blue-primary;
    }
  }
}