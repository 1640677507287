.align-left {
  float: left;
  margin-right: 1rem;
}
.align-right {
  float: right;
  margin-left: 1rem;
}
.align-center {
  &, & figure {
    display: flex;
    justify-content: center;
  }
}

.ez-embed-type-image {
  figure.mega_large,
  &__caption {
    margin-left: -400px !important;
    margin-right: -400px !important;
  
    @media screen and (min-width: 768px) {
      margin-left: calc(-80vw + 600px);
      margin-right: calc(-80vw + 600px);
    }
  }
  
  figure.mega_large {
    img {
      @apply w-screen xl:w-full xl:container mx-auto rounded md:rounded-xl;
      @media screen and (min-width: 1325px) {
        max-width: calc(1325px - 2rem) !important;
      }
    }
  }

  &__caption {
    label {
      @apply flex w-screen xl:w-full xl:container mx-auto text-4xxs md:text-sm px-4.25 my-3 md:my-5.75;
    }
  }
}